import React from 'react'
import { useEffect, useState  } from 'react';
import { Link , useLocation} from 'react-router-dom';

export default function Travelling() {
    const [selectedImage, setSelectedImage] = useState(null);

    const images = [
        'assets/img/travel/5.jpg',
        'assets/img/travel/2.jpg',
        'assets/img/travel/3.jpg',
        'assets/img/travel/4.jpg',
        'assets/img/travel/1.jpg',
        'assets/img/travel/6.jpg',
        'assets/img/travel/7.jpg',
        'assets/img/travel/8.jpg',
        'assets/img/travel/9.jpg',
        'assets/img/travel/10.jpg',
        'assets/img/travel/13.jpg',
        'assets/img/travel/14.jpg',
        'assets/img/travel/11.jpg',
        'assets/img/travel/12.jpg',
        'assets/img/travel/15.jpg',
        'assets/img/travel/16.jpg',
        'assets/img/travel/17.jpg',
        'assets/img/travel/18.jpg',
        'assets/img/travel/19.jpg',
        'assets/img/travel/20.jpg',
        'assets/img/travel/21.jpg',
        'assets/img/travel/22.jpg',
        'assets/img/travel/23.jpg',
        'assets/img/travel/33.jpg',
        'assets/img/travel/25.jpg',
        'assets/img/travel/26.jpg',
        'assets/img/travel/27.jpg',
        'assets/img/travel/28.jpg',
        'assets/img/travel/29.jpg',
        'assets/img/travel/30.jpg',
        'assets/img/travel/31.jpg',
        'assets/img/travel/32.jpg',
        'assets/img/travel/24.jpg',
        'assets/img/travel/34.jpg',
        'assets/img/travel/35.jpg',
        'assets/img/travel/37.jpg',
        'assets/img/travel/36.jpg',
        'assets/img/travel/38.jpg',
        'assets/img/travel/39.jpg',
        'assets/img/travel/40.jpg',
        'assets/img/travel/41.jpg',
        'assets/img/travel/42.jpg',
        'assets/img/travel/43.jpg',
        'assets/img/travel/44.jpg',
        'assets/img/travel/45.jpg',
        'assets/img/travel/46.jpg',
        'assets/img/travel/47.jpg',
        'assets/img/travel/48.jpg',
        'assets/img/travel/49.jpg',
        'assets/img/travel/52.jpg',
        'assets/img/travel/51.jpg',
        'assets/img/travel/50.jpg',
        'assets/img/travel/53.jpg',
        'assets/img/travel/54.jpg',
        'assets/img/travel/55.jpg',
        'assets/img/travel/56.jpg',
        'assets/img/travel/57.jpg',
        'assets/img/travel/58.jpg',
        'assets/img/travel/59.jpg',
        'assets/img/travel/60.jpg',
        'assets/img/travel/61.jpg',
        'assets/img/travel/62.jpg',
        'assets/img/travel/63.jpg',
        'assets/img/travel/64.jpg',
        'assets/img/travel/65.jpg',
        'assets/img/travel/66.jpg',
        'assets/img/travel/67.jpg',
        'assets/img/travel/68.jpg',
        'assets/img/travel/69.jpg',
        'assets/img/travel/76.jpg',
        'assets/img/travel/71.jpg',
        'assets/img/travel/72.jpg',
        'assets/img/travel/73.jpg',
        'assets/img/travel/74.jpg',
        'assets/img/travel/75.jpg',
        'assets/img/travel/70.jpg',
        'assets/img/travel/77.jpg',
        'assets/img/travel/78.jpg',
        'assets/img/travel/79.jpg',
        'assets/img/travel/80.jpg',
        'assets/img/travel/81.jpg',
        'assets/img/travel/82.jpg',
        'assets/img/travel/83.jpg',
        'assets/img/travel/85.jpg',
        'assets/img/travel/84.jpg',
        'assets/img/travel/86.jpg',
        'assets/img/travel/87.jpg',
        'assets/img/travel/88.jpg',
        'assets/img/travel/93.jpg',
        'assets/img/travel/90.jpg',
        'assets/img/travel/91.jpg',
        'assets/img/travel/92.jpg',
        'assets/img/travel/89.jpg',
        'assets/img/travel/94.jpg',
        'assets/img/travel/95.jpg',
        'assets/img/travel/96.jpg',
        'assets/img/travel/97.jpg',
        'assets/img/travel/98.jpg',
        'assets/img/travel/99.jpg'

    ];

    const openImage = (image) => {
        setSelectedImage(image);
    };

    const closeImage = () => {
        setSelectedImage(null);
    };

    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
    
      const [isSidebarVisible, setSidebarVisible] = useState(false);
      const location = useLocation();
      useEffect(() => {
        // Hide sidebar on route change
        setSidebarVisible(false);
      }, [location]);
    
      const handleToggle = () => {
        setSidebarVisible(!isSidebarVisible);
        document.body.classList.toggle('mobile-nav-active');
      };
    
      useEffect(() => {
        if (isSidebarVisible) {
          document.body.classList.add('mobile-nav-active');
        } else {
          document.body.classList.remove('mobile-nav-active');
        }
      }, [isSidebarVisible]);
      return (
        <div>
          
      {/* <i class="bi bi-list mobile-nav-toggle d-xl-none"></i> */}
    
     
      <header id="header">
        <div class="d-flex flex-column">
    
          <div class="profile">
            <img src="assets/img/propic.jpg" alt="" class="img-fluid rounded-circle"/>
            <h1 class="text-light"><a href="index.html">Migara Thiyunuwan</a></h1>
            <div class="social-links mt-3 text-center">
              <a href="https://web.facebook.com/migara.thiyunuwan.1/" class="facebook"><i class="bx bxl-facebook"></i></a>
              <a href="https://www.youtube.com/@Migara_Thiyunuwan" class="instagram"><i class="bx bxl-youtube"></i></a>
              <a href="https://github.com/MigaraThiyunuwan" class="google-plus"><i class="bx bxl-github"></i></a>
              <a href="https://www.instagram.com/______king_migara______?igsh=MTB5N3N0emEwdzV2aw==" class="twitter"><i class="bx bxl-instagram"></i></a>
              <a href="https://www.linkedin.com/in/migara-thiyunuwan/" class="linkedin"><i class="bx bxl-linkedin"></i></a>
            </div>
          </div>
    
          <button className="mobile-nav-toggle" onClick={handleToggle}>
          <img  src="assets/img/toggle.png" className="img-fluid" alt=""/>
          </button>
          <nav id="navbar" className={`nav-menu navbar ${isSidebarVisible ? 'show' : ''}`}>
            <ul>
              <li><Link to="/" onClick={handleToggle}><i className="bx bx-home"></i> <span>Home</span></Link></li>
              <li ><Link to="/about" onClick={handleToggle}><i className="bx bx-user"></i> <span>About</span></Link></li>
              <li><Link to="/resume" onClick={handleToggle}><i className="bx bx-file-blank"></i> <span>Resume</span></Link></li>
              <li><Link to="/myprojects" onClick={handleToggle}><i className="bx bx-book-content"></i> <span>Projects</span></Link></li>
              <li className="nav-link scrollto active"><Link to="/gallery" onClick={handleToggle}><i className="bx bx-image"></i> <span>Gallery</span></Link></li>
              <li><Link to="/achievements" onClick={handleToggle}><i className="bx bx-star"></i> <span>Achievements</span></Link></li>
              <li><Link to="/contact" onClick={handleToggle}><i className="bx bx-phone"></i> <span>Contact</span></Link></li>
            </ul>
          </nav>
        </div>
      </header>
    
      <main id="main">
    
       
        <section class="breadcrumbs">
          <div class="container">
    
            <div class="d-flex justify-content-between align-items-center">
            <Link to="/" >    <h2 style={{fontWeight:'bold', fontFamily:'inherit'}}>Migara Thiyunuwan</h2> </Link>
              <ol>
                <li><Link to="/">Home</Link></li>
                <li><Link to="/gallery">Gallery</Link></li>
                <li>Travelling</li>
              </ol>
            </div>
    
          </div>
        </section>
        
        <section className="resume">
        <div className="container">
        <div className="section-title">
          <h2>Travelling</h2>
          <p style={{ textAlign: 'justify' }}>
          Enthusiastic person with a love for traveling and hiking. 
          I explore new places and capture the beauty of nature through mobile photography.
           Eager to bring a fresh perspective to my projects while discovering and experiencing new horizons.</p>
        </div>
                <div className="tz-gallery">
                    <div className="row">
                        {images.map((image, index) => (
                            <div key={index} className="col-sm-6 col-md-4">
                                <a className="lightbox" onClick={() => openImage(image)}>
                                    <img src={image} alt={`Image ${index}`} />
                                </a>
                            </div>
                        ))}
                    </div>
                    {selectedImage && (
                        <div className="image-viewer-overlay" onClick={closeImage}>
                            <div className="image-container" onClick={e => e.stopPropagation()}>
                                <span className="close-button" onClick={closeImage}>X</span>
                                <img src={selectedImage} alt="Selected Image" />
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </section>
        
      </main>
    
     
      <footer id="footer">
        <div class="container">
         
          <div class="credits">
            
            Designed & Developed by <a href="{{ asset('/') }}">Migara Thiyunuwan</a>
          </div>
        </div>
      </footer>
    
      <a href="#" class="back-to-top d-flex align-items-center justify-content-center"><i
          class="bi bi-arrow-up-short"></i></a>
    
        </div>
  )
}
