import React from 'react'
import { useEffect, useState  } from 'react';
import { Link , useLocation} from 'react-router-dom';
import axios from 'axios';

export default function Contact() {

    const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Your EmailJS service ID, template ID, and Public Key
    const serviceId = 'service_yv59y4d';
    const templateId = 'template_dp8jccc';
    const publicKey = 'v7P5JtkcppHi21_KY';

    // Create an object with EmailJS service ID, template ID, Public Key, and Template params
    const data = {
      service_id: serviceId,
      template_id: templateId,
      user_id: publicKey,
      template_params: {
        from_name: name,
        from_email: email,
        to_name: 'Migara Thiyunuwan',
        message: message,
      }
    };

    // Send the email using EmailJS
    try {
      const res = await axios.post("https://api.emailjs.com/api/v1.0/email/send", data);
      console.log(res.data);
      setName('');
      setEmail('');
      setMessage('');
      setShowSuccessMessage(true);
    } catch (error) {
      console.error(error);
    }
  }



    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
      
      const [isSidebarVisible, setSidebarVisible] = useState(false);
      const location = useLocation();
      useEffect(() => {
        // Hide sidebar on route change
        setSidebarVisible(false);
      }, [location]);
    
      const handleToggle = () => {
        setSidebarVisible(!isSidebarVisible);
        document.body.classList.toggle('mobile-nav-active');
      };
    
      useEffect(() => {
        if (isSidebarVisible) {
          document.body.classList.add('mobile-nav-active');
        } else {
          document.body.classList.remove('mobile-nav-active');
        }
      }, [isSidebarVisible]);
      return (
        <div>
          
      {/* <i class="bi bi-list mobile-nav-toggle d-xl-none"></i> */}
    
     
      <header id="header">
        <div class="d-flex flex-column">
    
          <div class="profile">
            <img src="assets/img/propic.jpg" alt="" class="img-fluid rounded-circle"/>
            <h1 class="text-light"><a href="index.html">Migara Thiyunuwan</a></h1>
            <div class="social-links mt-3 text-center">
              <a href="https://web.facebook.com/migara.thiyunuwan.1/" class="facebook"><i class="bx bxl-facebook"></i></a>
              <a href="https://www.youtube.com/@Migara_Thiyunuwan" class="instagram"><i class="bx bxl-youtube"></i></a>
              <a href="https://github.com/MigaraThiyunuwan" class="google-plus"><i class="bx bxl-github"></i></a>
              <a href="https://www.instagram.com/______king_migara______?igsh=MTB5N3N0emEwdzV2aw==" class="twitter"><i class="bx bxl-instagram"></i></a>
              <a href="https://www.linkedin.com/in/migara-thiyunuwan/" class="linkedin"><i class="bx bxl-linkedin"></i></a>
            </div>
          </div>
    
          <button className="mobile-nav-toggle" onClick={handleToggle}>
          <img  src="assets/img/toggle.png" className="img-fluid" alt=""/>
          </button>
          <nav id="navbar" className={`nav-menu navbar ${isSidebarVisible ? 'show' : ''}`}>
            <ul>
              <li><Link to="/" onClick={handleToggle}><i className="bx bx-home"></i> <span>Home</span></Link></li>
              <li><Link to="/about" onClick={handleToggle}><i className="bx bx-user"></i> <span>About</span></Link></li>
              <li ><Link to="/resume" onClick={handleToggle}><i className="bx bx-file-blank"></i> <span>Resume</span></Link></li>
              <li><Link to="/myprojects" onClick={handleToggle}><i className="bx bx-book-content"></i> <span>Projects</span></Link></li>
              <li><Link to="/gallery" onClick={handleToggle}><i className="bx bx-image"></i> <span>Gallery</span></Link></li>
              <li><Link to="/achievements" onClick={handleToggle}><i className="bx bx-star"></i> <span>Achievements</span></Link></li>
              <li className="nav-link scrollto active"><Link to="/contact" onClick={handleToggle}><i className="bx bx-phone"></i> <span>Contact</span></Link></li>
            </ul>
          </nav>
        </div>
      </header>
    
      <main id="main">
    
       
        <section class="breadcrumbs">
          <div class="container">
    
            <div class="d-flex justify-content-between align-items-center">
            <Link to="/" >  <h2 style={{fontWeight:'bold', fontFamily:'inherit'}}>Migara Thiyunuwan</h2> </Link>
              <ol>
                <li><Link to="/">Home</Link></li>
                <li>Contact</li>
              </ol>
            </div>
    
          </div>
        </section>
    
        <section id="contact" className="contact">
      <div className="container">

        <div className="section-title">
          <h2>Contact and Feedback</h2>
          <p>Whether you're here out of curiosity or seeking collaboration, 
            I'm thrilled to connect. Drop me a line using the form below, 
            and let's start a conversation. Whether it's about a project, an idea, 
            or just to say hello, I'm eager to hear from you. Let's create something amazing together!</p>

            
        </div>

        <div className="row" data-aos="fade-in">

          <div className="col-lg-5 d-flex align-items-stretch">
            <div className="info">
              <div className="address">
                <i className="bi bi-geo-alt"></i>
                <h4>Location:</h4>
                <p>58/1, Waragoda, Aththanagalla.</p>
              </div>

              <div className="email">
                <i className="bi bi-envelope"></i>
                <h4>Email:</h4>
                <p>migarathiyunuwan@gmail.com</p>
              </div>

              <div className="phone">
                <i className="bi bi-phone"></i>
                <h4>Call:</h4>
                <p>+9477 14 16 968</p>
              </div>

              <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d7692.870548039896!2d80.14850759784075!3d7.109258757060544!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2slk!4v1717704469059!5m2!1sen!2slk" style={{ border: 0, width: '100%', height: '290px' }}   allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>

              
            </div>

          </div>

          <div className="col-lg-7 mt-5 mt-lg-0 d-flex align-items-stretch">
            <form onSubmit={handleSubmit} action="forms/contact.php" method="post" role="form" className="php-email-form">
              <div className="row">
                <div className="form-group col-md-6">
                  <label for="name">Your Name</label>
                  <input type="text" name="name" value={name} onChange={(e) => setName(e.target.value)} className="form-control" id="name" required/>
                </div>
                <div className="form-group col-md-6">
                  <label for="name">Your Email</label>
                  <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} className="form-control" name="email" id="email" required/>
                </div>
              </div>
              {/* <div className="form-group">
                <label for="name">Subject</label>
                <input type="text" className="form-control" name="subject" id="subject" required/>
              </div> */}
              <div className="form-group">
                <label for="name">Message</label>
                <textarea value={message} onChange={(e) => setMessage(e.target.value)} className="form-control" name="message" rows="10" required></textarea>
              </div>
              <div className="my-3">
                <div className="loading">Loading</div>
                <div className="error-message"></div>
                <div className="sent-message">Your message has been sent. Thank you!</div>
              </div>
              <div className="text-center"><button type="submit">Send Message</button></div>
              {showSuccessMessage && (
                    <div className='contactAlert'>
                    <div className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative" role="alert">
                        <strong className="font-bold">Message sent successfully!</strong>
                    </div>
                    </div>
                )}

            </form>

            
          </div>

        </div>

      </div>
    </section>
      </main>
    
     
      <footer id="footer">
        <div class="container">
         
          <div class="credits">
            
            Designed & Developed by <a href="{{ asset('/') }}">Migara Thiyunuwan</a>
          </div>
        </div>
      </footer>
    
      <a href="#" class="back-to-top d-flex align-items-center justify-content-center"><i
          class="bi bi-arrow-up-short"></i></a>
    
    
     
        </div>
      )
}
