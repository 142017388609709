import React from 'react'
import { useEffect, useState  } from 'react';
import { Link , useLocation} from 'react-router-dom';


export default function MobilePhotography() {
    const [selectedImage, setSelectedImage] = useState(null);

    const images = [
        'assets/img/mobilep/1.jpg',
        'assets/img/mobilep/2.jpg',
        'assets/img/mobilep/3.jpg',
        'assets/img/mobilep/21.jpg',
        'assets/img/mobilep/5.jpg',
        'assets/img/mobilep/6.jpg',
        'assets/img/mobilep/7.jpg',
        'assets/img/mobilep/8.jpg',
        'assets/img/mobilep/9.jpg',
        'assets/img/mobilep/10.jpg',
        'assets/img/mobilep/11.jpg',
        'assets/img/mobilep/19.jpg',
        'assets/img/mobilep/13.jpg',
        'assets/img/mobilep/14.jpg',
        'assets/img/mobilep/15.jpg',
        'assets/img/mobilep/16.jpg',
        'assets/img/mobilep/17.jpg',
        'assets/img/mobilep/20.jpg',
        'assets/img/mobilep/12.jpg',
        'assets/img/mobilep/18.jpg',
        'assets/img/mobilep/4.jpg'
    ];

    const openImage = (image) => {
        setSelectedImage(image);
    };

    const closeImage = () => {
        setSelectedImage(null);
    };

    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
    
      const [isSidebarVisible, setSidebarVisible] = useState(false);
      const location = useLocation();
      useEffect(() => {
        // Hide sidebar on route change
        setSidebarVisible(false);
      }, [location]);
    
      const handleToggle = () => {
        setSidebarVisible(!isSidebarVisible);
        document.body.classList.toggle('mobile-nav-active');
      };
    
      useEffect(() => {
        if (isSidebarVisible) {
          document.body.classList.add('mobile-nav-active');
        } else {
          document.body.classList.remove('mobile-nav-active');
        }
      }, [isSidebarVisible]);
      return (
        <div>
          
      {/* <i class="bi bi-list mobile-nav-toggle d-xl-none"></i> */}
    
     
      <header id="header">
        <div class="d-flex flex-column">
    
          <div class="profile">
            <img src="assets/img/propic.jpg" alt="" class="img-fluid rounded-circle"/>
            <h1 class="text-light"><a href="index.html">Migara Thiyunuwan</a></h1>
            <div class="social-links mt-3 text-center">
              <a href="https://web.facebook.com/migara.thiyunuwan.1/" class="facebook"><i class="bx bxl-facebook"></i></a>
              <a href="https://www.youtube.com/@Migara_Thiyunuwan" class="instagram"><i class="bx bxl-youtube"></i></a>
              <a href="https://github.com/MigaraThiyunuwan" class="google-plus"><i class="bx bxl-github"></i></a>
              <a href="https://www.instagram.com/______king_migara______?igsh=MTB5N3N0emEwdzV2aw==" class="twitter"><i class="bx bxl-instagram"></i></a>
              <a href="https://www.linkedin.com/in/migara-thiyunuwan/" class="linkedin"><i class="bx bxl-linkedin"></i></a>
            </div>
          </div>
    
          <button className="mobile-nav-toggle" onClick={handleToggle}>
          <img  src="assets/img/toggle.png" className="img-fluid" alt=""/>
          </button>
          <nav id="navbar" className={`nav-menu navbar ${isSidebarVisible ? 'show' : ''}`}>
            <ul>
              <li><Link to="/" onClick={handleToggle}><i className="bx bx-home"></i> <span>Home</span></Link></li>
              <li ><Link to="/about" onClick={handleToggle}><i className="bx bx-user"></i> <span>About</span></Link></li>
              <li><Link to="/resume" onClick={handleToggle}><i className="bx bx-file-blank"></i> <span>Resume</span></Link></li>
              <li><Link to="/myprojects" onClick={handleToggle}><i className="bx bx-book-content"></i> <span>Projects</span></Link></li>
              <li className="nav-link scrollto active"><Link to="/gallery" onClick={handleToggle}><i className="bx bx-image"></i> <span>Gallery</span></Link></li>
              <li><Link to="/achievements" onClick={handleToggle}><i className="bx bx-star"></i> <span>Achievements</span></Link></li>
              <li><Link to="/contact" onClick={handleToggle}><i className="bx bx-phone"></i> <span>Contact</span></Link></li>
            </ul>
          </nav>
        </div>
      </header>
    
      <main id="main">
    
       
        <section class="breadcrumbs">
          <div class="container">
    
            <div class="d-flex justify-content-between align-items-center">
            <Link to="/" >  <h2 style={{fontWeight:'bold', fontFamily:'inherit'}}>Migara Thiyunuwan</h2> </Link>
              <ol>
                <li><Link to="/">Home</Link></li>
                <li><Link to="/gallery">Gallery</Link></li>
                <li>Mobile Photography</li>
              </ol>
            </div>
    
          </div>
        </section>
        
        <section className="resume">
        <div className="container">
        <div className="section-title">
          <h2>Mobile Photography</h2>
          <p style={{ textAlign: 'justify' }}>
          Passionate undergraduate student with a keen interest in mobile photography. 
          Combining technical skills with a creative eye, I capture compelling moments through my lens.
           Eager to showcase my unique perspective and contribute to innovative digital projects.</p>
        </div>
                <div className="tz-gallery">
                    <div className="row">
                        {images.map((image, index) => (
                            <div key={index} className="col-sm-6 col-md-4">
                                <a className="lightbox" onClick={() => openImage(image)}>
                                    <img src={image} alt={`Image ${index}`} />
                                </a>
                            </div>
                        ))}
                    </div>
                    {selectedImage && (
                        <div className="image-viewer-overlay" onClick={closeImage}>
                            <div className="image-container" onClick={e => e.stopPropagation()}>
                                <span className="close-button" onClick={closeImage}>X</span>
                                <img src={selectedImage} alt="Selected Image" />
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </section>
        
      </main>
    
     
      <footer id="footer">
        <div class="container">
         
          <div class="credits">
            
            Designed & Developed by <a href="{{ asset('/') }}">Migara Thiyunuwan</a>
          </div>
        </div>
      </footer>
    
      <a href="#" class="back-to-top d-flex align-items-center justify-content-center"><i
          class="bi bi-arrow-up-short"></i></a>
    
        </div>
      )
}
