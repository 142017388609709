import React from 'react'
import { useEffect, useState  } from 'react';
import { Link , useLocation} from 'react-router-dom';

export default function Achievements() {
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
  
    const [isSidebarVisible, setSidebarVisible] = useState(false);
    const location = useLocation();
    useEffect(() => {
      // Hide sidebar on route change
      setSidebarVisible(false);
    }, [location]);
  
    const handleToggle = () => {
      setSidebarVisible(!isSidebarVisible);
      document.body.classList.toggle('mobile-nav-active');
    };
  
    useEffect(() => {
      if (isSidebarVisible) {
        document.body.classList.add('mobile-nav-active');
      } else {
        document.body.classList.remove('mobile-nav-active');
      }
    }, [isSidebarVisible]);
    return (
      <div>
        
    {/* <i class="bi bi-list mobile-nav-toggle d-xl-none"></i> */}
  
   
    <header id="header">
      <div class="d-flex flex-column">
  
        <div class="profile">
          <img src="assets/img/propic.jpg" alt="" class="img-fluid rounded-circle"/>
          <h1 class="text-light"><a href="index.html">Migara Thiyunuwan</a></h1>
          <div class="social-links mt-3 text-center">
            <a href="https://web.facebook.com/migara.thiyunuwan.1/" class="facebook"><i class="bx bxl-facebook"></i></a>
            <a href="https://www.youtube.com/@Migara_Thiyunuwan" class="instagram"><i class="bx bxl-youtube"></i></a>
            <a href="https://github.com/MigaraThiyunuwan" class="google-plus"><i class="bx bxl-github"></i></a>
            <a href="https://www.instagram.com/______king_migara______?igsh=MTB5N3N0emEwdzV2aw==" class="twitter"><i class="bx bxl-instagram"></i></a>
            <a href="https://www.linkedin.com/in/migara-thiyunuwan/" class="linkedin"><i class="bx bxl-linkedin"></i></a>
          </div>
        </div>
  
        <button className="mobile-nav-toggle" onClick={handleToggle}>
        <img  src="assets/img/toggle.png" className="img-fluid" alt=""/>
        </button>
        <nav id="navbar" className={`nav-menu navbar ${isSidebarVisible ? 'show' : ''}`}>
          <ul>
            <li><Link to="/" onClick={handleToggle}><i className="bx bx-home"></i> <span>Home</span></Link></li>
            <li ><Link to="/about" onClick={handleToggle}><i className="bx bx-user"></i> <span>About</span></Link></li>
            <li><Link to="/resume" onClick={handleToggle}><i className="bx bx-file-blank"></i> <span>Resume</span></Link></li>
            <li ><Link to="/myprojects" onClick={handleToggle}><i className="bx bx-book-content"></i> <span>Projects</span></Link></li>
            <li><Link to="/gallery" onClick={handleToggle}><i className="bx bx-image"></i> <span>Gallery</span></Link></li>
            <li className="nav-link scrollto active"><Link to="/achievements" onClick={handleToggle}><i className="bx bx-star"></i> <span>Achievements</span></Link></li>
            <li><Link to="/contact" onClick={handleToggle}><i className="bx bx-phone"></i> <span>Contact</span></Link></li>
          </ul>
        </nav>
      </div>
    </header>
  
    <main id="main">
  
     
      <section class="breadcrumbs">
        <div class="container">
  
          <div class="d-flex justify-content-between align-items-center">
          <Link to="/" > <h2 style={{fontWeight:'bold', fontFamily:'inherit'}}>Migara Thiyunuwan</h2> </Link>
            <ol>
              <li><Link to="/">Home</Link></li>
              <li>Achievements</li>
            </ol>
          </div>
  
        </div>
      </section>
  
      <section id="about" class="about">
        <div class="container">
  
          <div class="section-title">
            <h2>My Achievements</h2>
            <p style={{ textAlign: 'justify' }}> As an undergraduate student, I've embarked on a journey fueled by curiosity, dedication, and 
            a relentless pursuit of excellence. Throughout my academic tenure, I've actively sought opportunities to challenge
             myself, broaden my horizons, and make meaningful contributions within and beyond the classroom.</p>
          </div>

          <div style={{marginTop:20, paddingBottom:20}}  class="row ">
            <div  style={{ marginTop: '20px',  marginBottom:'30px' }}  className='content'>
            <h3>Participated in UvaXtreme 1.0, A programming competition</h3>
            </div>
            <div class="col-lg-6 " data-aos="fade-right">
          
              <img src="assets/img/achievements/uvaxtremeparticipation.jpg" class="img-fluid" alt=""/>
            </div>
            <div class="col-lg-6 pt-4 pt-lg-0 content" data-aos="fade-left">
             
              
              <div class="row" style={{ marginTop: '20px' }}>
                <div class="col-lg-11">
                <p style={{ textAlign: 'justify' }}>
              
                On June 29, 2024, I participated in UvaXtreme 1.0, a programming competition organized by the IEEE 
                Student Branch at Uva Wellassa University in collaboration with the CS Community. This event provided 
                an excellent opportunity to challenge my coding skills and connect with fellow enthusiasts. I am proud
                 to have been a part of this competitive and stimulating environment, which significantly contributed to 
                 my growth in the field of programming
                    </p>

                </div>
              </div>
            </div>
          </div>
  
          <div class="row ">
            <div  style={{ marginTop: '30px',  marginBottom:'30px' }}  className='content'>
            <h3>Member of the Membership Development Standing Committee in the 
                IEEE Industry Applications Society at Uva Wellassa University</h3>
            </div>
            <div class="col-lg-6 " data-aos="fade-right">
          
              <img src="assets/img/achievements/ias.png" class="img-fluid" alt=""/>
            </div>
            <div class="col-lg-6 pt-4 pt-lg-0 content" data-aos="fade-left">
             
              
              <div class="row" style={{ marginTop: '20px' }}>
                <div class="col-lg-11">
                <p style={{ textAlign: 'justify' }}>
              
                As a dedicated member of the Membership Development Standing Committee in the 
                IEEE Industry Applications Society at Uva Wellassa University, I played a 
                pivotal role in enhancing student engagement and fostering professional growth within the society. 
                My contributions included organizing events, spearheading membership drives, and collaborating with 
                industry professionals to provide valuable networking opportunities for students. This experience 
                honed my leadership and organizational skills, while deepening my understanding of industry applications
                 and professional development in engineering.
                        
                    </p>

                </div>
              </div>
            </div>
          </div>

          <div style={{marginTop:20, paddingBottom:20}}  class="row ">
            <div  style={{ marginTop: '20px',  marginBottom:'30px' }}  className='content'>
            <h3>Appointed as a committee member of the FOSS Community at Uva Wellassa University for the year 2023/2024</h3>
            </div>
            <div class="col-lg-6 " data-aos="fade-right">
          
              <img src="assets/img/achievements/foss.png" class="img-fluid" alt=""/>
            </div>
            <div class="col-lg-6 pt-4 pt-lg-0 content" data-aos="fade-left">
             
              
              <div class="row" style={{ marginTop: '20px' }}>
                <div class="col-lg-11">
                <p style={{ textAlign: 'justify' }}>
              
                I am honored to have been appointed as a committee member of the FOSS Community at Uva Wellassa University of Sri Lanka.
                 As a Computer Science undergraduate, this role allows me to actively contribute to the promotion and development of 
                 free and open-source software within our academic community. Engaging with fellow enthusiasts and collaborating on 
                 various projects, I am committed to fostering an environment of innovation and shared learning. This experience not 
                 only enhances my technical skills but also underscores my dedication to the principles of open-source collaboration 
                 and community-driven progress.
                    </p>

                </div>
              </div>
            </div>
          </div>

          <div class="row ">
            <div  style={{ marginTop: '20px',  marginBottom:'30px' }}  className='content'>
            <h3>Volunteering for Community Empowerment - "Sihinayata Athwelak" Project</h3>
            </div>
            <div class="col-lg-6 " data-aos="fade-right">
          
              <img src="assets/img/achievements/sihinayata.png" class="img-fluid" alt=""/>
            </div>
            <div class="col-lg-6 pt-4 pt-lg-0 content" data-aos="fade-left">
              
              
              <div class="row" style={{ marginTop: '20px' }}>
                <div class="col-lg-11">
                    <p style={{ textAlign: 'justify' }}>
                    
                    I had the privilege to volunteer for the "Sihinayata Athwelak" project, organized by the IEEE Student Branch of 
                    Uva Wellassa University in collaboration with AIESEC. On December 19th, I engaged with the vibrant young minds at
                     B/ Kandagolla Secondary School, teaching them basic information technology skills. This experience was immensely 
                     fulfilling, allowing me to contribute to my community by empowering school children with essential IT knowledge. 
                     I am grateful for the opportunity to inspire and support the next generation through this impactful initiative.
                                
                    </p>
                    

                </div>
              </div>
            </div>
          </div>

          <div style={{marginTop:20, paddingBottom:20}}  class="row">
            <div  style={{ marginTop: '20px',  marginBottom:'30px' }}  className='content '>
            <h3>Runners-up in the LUDICON COD4 Championship - 2024 organized by university of kelaniya</h3>
            </div>
            <div class="col-lg-4 " data-aos="fade-right">
          
              <img src="assets/img/achievements/ludicon.jpg" class="img-fluid" alt=""/>
            </div>
            <div class="col-lg-8 pt-4 pt-lg-0 content" data-aos="fade-left">
              
              
              <div class="row" style={{ marginTop: '20px' }}>
                <div class="col-lg-11">
                    <p style={{ textAlign: 'justify' }}>
                    
                        I am proud to present the certificate awarded to me for exceptional skill, sportsmanship, 
                        and coordination in the realm of competitive gaming. As a key member of Team Rivalz from
                        Uva Wellassa University, I significantly contributed to our success, leading us to finish 
                        as the runners-up in the LUDICON COD4 Championship held on March 29-31, 2024. In addition 
                        to my passion for programming, I am also an avid video gamer, enjoying both the technical 
                        and recreational aspects of gaming.
                                
                    </p>
                    

                </div>
              </div>
            </div>
          </div>

          <div class="row ">
            <div  style={{ marginTop: '20px',  marginBottom:'30px' }}  className='content'>
            <h3>Core Member of Computer Society, Uva Wellassa University (2022/2023)</h3>
            </div>
            <div class="col-lg-4 " data-aos="fade-right">
          
              <img src="assets/img/achievements/comsoc.png" class="img-fluid" alt=""/>
            </div>
            <div class="col-lg-8 pt-4 pt-lg-0 content" data-aos="fade-left">
              
              
              <div class="row" style={{ marginTop: '20px' }}>
                <div class="col-lg-11">
                    <p style={{ textAlign: 'justify' }}>
                    
                    I am honored to have served as a Core Member of the Computer Society at Uva Wellassa University 
                    for the 2022/2023 academic year. During my tenure, I actively contributed to fostering a vibrant 
                    community for computer science enthusiasts, organizing various workshops, seminars, and coding 
                    competitions that enhanced the technical skills and knowledge of our members. My role involved 
                    collaborating with peers to design and implement initiatives that promoted innovation, teamwork,
                     and continuous learning within the society. This experience not only honed my leadership and 
                     organizational abilities but also reinforced my commitment to advancing the field of computer science.
                                
                    </p>
                    

                </div>
              </div>
            </div>
          </div>

          <div style={{marginTop:20, paddingBottom:20}}  class="row ">
            <div  style={{ marginTop: '20px',  marginBottom:'30px' }}  className='content '>
            <h3>Volunteers of IEEE Uva Wellassa University Student Branch for November 2022</h3>
            </div>
            <div class="col-lg-6 " data-aos="fade-right">
          
              <img src="assets/img/achievements/vom.png" class="img-fluid" alt=""/>
            </div>
            <div class="col-lg-6 pt-4 pt-lg-0 content" data-aos="fade-left">
              
              
              <div class="row" style={{ marginTop: '20px' }}>
                <div class="col-lg-11">
                    <p style={{ textAlign: 'justify' }}>
                    
                    In November 2022, I had the privilege of volunteering with the IEEE Uva Wellassa University Student Branch.
                     During this time, I actively contributed to organizing and executing various technical and 
                     community-driven events, aimed at fostering innovation and collaboration among students and professionals 
                     in the field of engineering and technology. My involvement not only enhanced my leadership and teamwork 
                     skills but also provided me with invaluable experiences in project management and event coordination. 
                     This opportunity allowed me to connect with like-minded peers and industry experts, further fueling my 
                     passion for technology and community service.
                                
                    </p>
                    

                </div>
              </div>
            </div>
          </div>
          
          <div class="row ">
            <div  style={{ marginTop: '20px',  marginBottom:'30px' }}  className='content'>
            <h3>Championship Triumph: Leading the 'Team Rivalz' to Victory in EAblaze Cyber Games E-Sports Competition 2020</h3>
            </div>
            <div class="col-lg-6 " data-aos="fade-right">
          
              <img src="assets/img/achievements/eablaze.jpeg" class="img-fluid" alt=""/>
            </div>
            <div class="col-lg-6 pt-4 pt-lg-0 content" data-aos="fade-left">
              
              
              <div class="row" style={{ marginTop: '20px' }}>
                <div class="col-lg-11">
                    <p style={{ textAlign: 'justify' }}>
                    
                    As a member of the "Team Rivalz" team, I proudly participated in the E-Ablaze Cyber Games E-Sports Competition 2020, 
                    organized by the E-15 batch of the Faculty of Engineering at South Eastern University of Sri Lanka. 
                    The event took place on January 27th, 2020, at the university premises. Our team showcased exceptional skill
                     and teamwork in the Call of Duty 4 (COD4) tournament, ultimately securing the championship title. This victory 
                     not only highlights my proficiency in competitive gaming but also underscores my ability to collaborate effectively 
                     within a team to achieve a common goal.
                                
                    </p>
                    

                </div>
              </div>
            </div>
          </div>

          <div style={{marginTop:20, paddingBottom:20}}  class="row ">
            <div  style={{ marginTop: '20px',  marginBottom:'30px' }}  className='content '>
            <h3>
            Participant in IEEE Xtreme Programming Competitions</h3>
            </div>
            <div class="col-lg-6 " data-aos="fade-right">
          
              <img src="assets/img/achievements/xtreme16.png" class="img-fluid" alt=""/>
            </div>
            <div class="col-lg-6 pt-4 pt-lg-0 content" data-aos="fade-left">
              
              
              <div class="row" >
              <div class="col-lg-12 " data-aos="fade-right">
          
              <img src="assets/img/achievements/xtreme15.png" class="img-fluid" alt=""/>
              </div>
                {/* <div class="col-lg-11">
                    <p style={{ textAlign: 'justify' }}>
                    
                    In November 2022, I had the privilege of volunteering with the IEEE Uva Wellassa University Student Branch.
                     During this time, I actively contributed to organizing and executing various technical and 
                     community-driven events, aimed at fostering innovation and collaboration among students and professionals 
                     in the field of engineering and technology. My involvement not only enhanced my leadership and teamwork 
                     skills but also provided me with invaluable experiences in project management and event coordination. 
                     This opportunity allowed me to connect with like-minded peers and industry experts, further fueling my 
                     passion for technology and community service.
                                
                    </p>
                    

                </div> */}
              </div>
            </div>
          </div>

        </div>

      </section>
  
  
      
  
      
    </main>
  
   
    <footer id="footer">
      <div class="container">
       
        <div class="credits">
          
          Designed & Developed by <a href="{{ asset('/') }}">Migara Thiyunuwan</a>
        </div>
      </div>
    </footer>
  
    <a href="#" class="back-to-top d-flex align-items-center justify-content-center"><i
        class="bi bi-arrow-up-short"></i></a>
  
      </div>
    )
}
