import logo from './logo.svg';
import './App.css';
import PartOne from './components/PartOne';
import Home from './Pages/Home';
import About from './Pages/About';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Resume from './Pages/Resume';
import MobilePhotography from './Pages/MobilePhotography';
import Travelling from './Pages/Travelling';
import Gllery from './Pages/Gllery';
import Projects from './Pages/Projects';
import Achievements from './Pages/Achievements';
import Contact from './Pages/Contact';
function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home/>}></Route>
        <Route path="/about" element={<About/>}></Route>
        <Route path="/resume" element={<Resume/>}></Route>
        <Route path="/mobilephotography" element={<MobilePhotography/>}></Route>
        <Route path="/travelling" element={<Travelling/>}> </Route>
        <Route path="/gallery" element={<Gllery/>}></Route>
        <Route path="/myprojects" element={<Projects/>}></Route>
        <Route path="/achievements" element={<Achievements/>}></Route>
        <Route path="/contact" element={<Contact/>}></Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
