import React from 'react'
import { useEffect, useState  } from 'react';
import { Link , useLocation} from 'react-router-dom';

export default function About() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const [isSidebarVisible, setSidebarVisible] = useState(false);
  const location = useLocation();
  useEffect(() => {
    // Hide sidebar on route change
    setSidebarVisible(false);
  }, [location]);

  const handleToggle = () => {
    setSidebarVisible(!isSidebarVisible);
    document.body.classList.toggle('mobile-nav-active');
  };

  useEffect(() => {
    if (isSidebarVisible) {
      document.body.classList.add('mobile-nav-active');
    } else {
      document.body.classList.remove('mobile-nav-active');
    }
  }, [isSidebarVisible]);
  return (
    <div>
      
  {/* <i class="bi bi-list mobile-nav-toggle d-xl-none"></i> */}

 
  <header id="header">
    <div class="d-flex flex-column">

      <div class="profile">
        <img src="assets/img/propic.jpg" alt="" class="img-fluid rounded-circle"/>
        <h1 class="text-light"><a href="index.html">Migara Thiyunuwan</a></h1>
        <div class="social-links mt-3 text-center">
          <a href="https://web.facebook.com/migara.thiyunuwan.1/" class="facebook"><i class="bx bxl-facebook"></i></a>
          <a href="https://www.youtube.com/@Migara_Thiyunuwan" class="instagram"><i class="bx bxl-youtube"></i></a>
          <a href="https://github.com/MigaraThiyunuwan" class="google-plus"><i class="bx bxl-github"></i></a>
          <a href="https://www.instagram.com/______king_migara______?igsh=MTB5N3N0emEwdzV2aw==" class="twitter"><i class="bx bxl-instagram"></i></a>
          <a href="https://www.linkedin.com/in/migara-thiyunuwan/" class="linkedin"><i class="bx bxl-linkedin"></i></a>
        </div>
      </div>

      <button className="mobile-nav-toggle" onClick={handleToggle}>
      <img  src="assets/img/toggle.png" className="img-fluid" alt=""/>
      </button>
      <nav id="navbar" className={`nav-menu navbar ${isSidebarVisible ? 'show' : ''}`}>
        <ul>
          <li><Link to="/" onClick={handleToggle}><i className="bx bx-home"></i> <span>Home</span></Link></li>
          <li className="nav-link scrollto active"><Link to="/about" onClick={handleToggle}><i className="bx bx-user"></i> <span>About</span></Link></li>
          <li><Link to="/resume" onClick={handleToggle}><i className="bx bx-file-blank"></i> <span>Resume</span></Link></li>
          <li><Link to="/myprojects" onClick={handleToggle}><i className="bx bx-book-content"></i> <span>Projects</span></Link></li>
          <li><Link to="/gallery" onClick={handleToggle}><i className="bx bx-image"></i> <span>Gallery</span></Link></li>
          <li><Link to="/achievements" onClick={handleToggle}><i className="bx bx-star"></i> <span>Achievements</span></Link></li>
          <li><Link to="/contact" onClick={handleToggle}><i className="bx bx-phone"></i> <span>Contact</span></Link></li>
        </ul>
      </nav>
    </div>
  </header>

  <main id="main">

   
    <section class="breadcrumbs">
      <div class="container">

        <div class="d-flex justify-content-between align-items-center">
        <Link to="/" > <h2 style={{fontWeight:'bold', fontFamily:'inherit'}}>Migara Thiyunuwan</h2> </Link>
          <ol>
            <li><Link to="/">Home</Link></li>
            <li>About</li>
          </ol>
        </div>

      </div>
    </section>

    <section id="about" class="about">
      <div class="container">

        <div class="section-title">
          <h2>About</h2>
          <p style={{ textAlign: 'justify' }}>I’m Migara Thiyunuwan, a 25-year-old undergraduate student currently pursuing a Bsc in Computer 
            Science and Technology degree program at Uva Wellassa University of Sri Lanka.</p>
        </div>

        <div class="row">
          <div class="col-lg-4" data-aos="fade-right">
            <img src="assets/img/propic.jpg" class="img-fluid" alt=""/>
          </div>
          <div class="col-lg-8 pt-4 pt-lg-0 content" data-aos="fade-left">
            <h3>My Information</h3>
            
            <div class="row" style={{ marginTop: '30px' }}>
              <div class="col-lg-6">
                <ul>
                  <li><i class="bi bi-chevron-right"></i> <strong>Birthday:</strong> <span>27 November 1999</span></li>
                  <li><i class="bi bi-chevron-right"></i> <strong>Website:</strong> <span>www.migarathiyunuwan.me</span></li>
                  <li><i class="bi bi-chevron-right"></i> <strong>Phone:</strong> <span>+9477 14 16 968</span></li>
                  <li><i class="bi bi-chevron-right"></i> <strong>City:</strong> <span>Aththanagalla, Sri Lanka</span></li>
                  <li><i class="bi bi-chevron-right"></i> <strong>Languages:</strong> <span>English / Sinhala</span></li>
                </ul>
              </div>
              <div class="col-lg-6">
                <ul>
                  <li><i class="bi bi-chevron-right"></i> <strong>Age:</strong> <span>25</span></li>
                  <li><i class="bi bi-chevron-right"></i> <strong>Degree:</strong> <span>Bsc in Computer Science & Technology</span></li>
                  <li><i class="bi bi-chevron-right"></i> <strong>Email:</strong> <span>migarathiyunuwan@gmail.com</span></li>
                  <li><i class="bi bi-chevron-right"></i> <strong>Nationality:</strong> <span>Sri Lankan</span></li>
                  <li><i class="bi bi-chevron-right"></i> <strong>Freelance:</strong> <span>Available</span></li>
                </ul>
              </div>
            </div>
            <p style={{ marginTop: '10px', textAlign: 'justify' }}>
            As a full-stack development enthusiast, I thrive on creating seamless user experiences by combining front-end aesthetics
             with back-end functionality. Whether it’s crafting responsive web interfaces or optimizing database queries,
              I’m always up for a coding challenge. My curiosity extends beyond the classroom—I’m constantly exploring new technologies,
               attending hackathons, and contributing to open-source projects. <br></br> <br></br>
               Let’s connect! Whether it’s discussing the latest tech trends or collaborating on a coding project, I’m excited to learn, 
               grow, and make an impact in the world of technology. 🚀
               
            </p>
            
          </div>
        </div>

      </div>
    </section>


    <section id="skills" className="skills">
    <div class="container">
        <div class="section-title">
          <h2>My Tech Stack</h2>
          <p style={{  textAlign: 'justify' }}>Here are the key technologies I frequently use in my projects. This selection reflects my expertise in both front-end and back-end development, allowing me to build robust and efficient applications.</p>
        </div >
      
        <div className='techStack' >
          
          
            <img src="assets/img/logos/c.png" class="img-fluid" alt=""/>
            <img src="assets/img/logos/androidStd.png" class="img-fluid" alt=""/>
            <img src="assets/img/logos/apache.png" class="img-fluid" alt=""/>
            <img src="assets/img/logos/boostrap.png" class="img-fluid" alt=""/>
          
          
          
            <img src="assets/img/logos/canva.png" class="img-fluid" alt=""/>
            <img src="assets/img/logos/css.png" class="img-fluid" alt=""/>
            <img src="assets/img/logos/django.png" class="img-fluid" alt=""/>
            <img src="assets/img/logos/figma.png" class="img-fluid" alt=""/>
          
        </div>
        
        <div className='techStack'>
          
          
            <img src="assets/img/logos/git.png" class="img-fluid" alt=""/>
            <img src="assets/img/logos/github.png" class="img-fluid" alt=""/>
            <img src="assets/img/logos/html.png" class="img-fluid" alt=""/>
            <img src="assets/img/logos/java.png" class="img-fluid" alt=""/>
          
          
          
            <img src="assets/img/logos/js.png" class="img-fluid" alt=""/>
            <img src="assets/img/logos/kotlin.png" class="img-fluid" alt=""/>
            <img src="assets/img/logos/laravel.png" class="img-fluid" alt=""/>
            <img src="assets/img/logos/mysql.png" class="img-fluid" alt=""/>
          
        </div>
        <div className='techStack'>
          
          
            <img src="assets/img/logos/php.png" class="img-fluid" alt=""/>
            <img src="assets/img/logos/pr.png" class="img-fluid" alt=""/>
            <img src="assets/img/logos/ps.png" class="img-fluid" alt=""/>
            <img src="assets/img/logos/python.png" class="img-fluid" alt=""/>
          
          
          
            <img src="assets/img/logos/react.png" class="img-fluid" alt=""/>
            <img src="assets/img/logos/spring.png" class="img-fluid" alt=""/>
            <img src="assets/img/logos/tailwind.png" class="img-fluid" alt=""/>
            <img src="assets/img/logos/vscode.png" class="img-fluid" alt=""/>
          
        </div>
    </div>
    </section>

    <section id="skills" className="skills facts">
      <div className="container">

        <div className="section-title">
          <h2>My Skills</h2>
          <p  style={{textAlign: 'justify' }}>I possess a diverse set of skills that enhance my ability to deliver high-quality projects. These skills range from programming 
            languages and development methodologies to problem-solving and teamwork capabilities.
             My proficiency in these areas ensures that I can effectively tackle challenges and contribute to the success of any project.</p>
        </div>

        <div className="row skills-content">

          <div className="col-lg-6" data-aos="fade-up">

          <div className="progress">
          <span className="skill">HTML <i className="val">95%</i></span>
          <div className="progress-bar-wrap">
            <div
              className="progress-bar"
              role="progressbar"
              aria-valuenow="100"
              aria-valuemin="0"
              aria-valuemax="100"
              style={{ width: '95%' }}
            >
            </div>
          </div>
        </div>

        <div className="progress">
          <span className="skill">CSS <i className="val">90%</i></span>
          <div className="progress-bar-wrap">
            <div
              className="progress-bar"
              role="progressbar"
              aria-valuenow="90"
              aria-valuemin="0"
              aria-valuemax="100"
              style={{ width: '90%' }}
            >
            </div>
          </div>
        </div>

        <div className="progress">
          <span className="skill">JavaScript <i className="val">65%</i></span>
          <div className="progress-bar-wrap">
            <div
              className="progress-bar"
              role="progressbar"
              aria-valuenow="75"
              aria-valuemin="0"
              aria-valuemax="100"
              style={{ width: '65%' }}
            >
            </div>
          </div>
        </div>
        <div className="progress">
          <span className="skill">React<i className="val">60%</i></span>
          <div className="progress-bar-wrap">
            <div
              className="progress-bar"
              role="progressbar"
              aria-valuenow="75"
              aria-valuemin="0"
              aria-valuemax="100"
              style={{ width: '60%' }}
            >
            </div>
          </div>
        </div>

          </div>

          <div className="col-lg-6" data-aos="fade-up" data-aos-delay="100">

          <div className="progress">
          <span className="skill">PHP <i className="val">80%</i></span>
          <div className="progress-bar-wrap">
            <div
              className="progress-bar"
              role="progressbar"
              aria-valuenow="100"
              aria-valuemin="0"
              aria-valuemax="100"
              style={{ width: '80%' }}
            >
            </div>
          </div>
        </div>

        <div className="progress">
          <span className="skill">JAVA <i className="val">75%</i></span>
          <div className="progress-bar-wrap">
            <div
              className="progress-bar"
              role="progressbar"
              aria-valuenow="90"
              aria-valuemin="0"
              aria-valuemax="100"
              style={{ width: '75%' }}
            >
            </div>
          </div>
        </div>

        <div className="progress">
          <span className="skill">C <i className="val">90%</i></span>
          <div className="progress-bar-wrap">
            <div
              className="progress-bar"
              role="progressbar"
              aria-valuenow="75"
              aria-valuemin="0"
              aria-valuemax="100"
              style={{ width: '90%' }}
            >
            </div>
          </div>
        </div>
        <div className="progress">
          <span className="skill">Laravel <i className="val">65%</i></span>
          <div className="progress-bar-wrap">
            <div
              className="progress-bar"
              role="progressbar"
              aria-valuenow="75"
              aria-valuemin="0"
              aria-valuemax="100"
              style={{ width: '65%' }}
            >
            </div>
          </div>
        </div>

          </div>

        </div>

      </div>
    </section>
  </main>

 
  <footer id="footer">
    <div class="container">
     
      <div class="credits">
        
        Designed & Developed by <a href="{{ asset('/') }}">Migara Thiyunuwan</a>
      </div>
    </div>
  </footer>

  <a href="#" class="back-to-top d-flex align-items-center justify-content-center"><i
      class="bi bi-arrow-up-short"></i></a>

    </div>
  )
}
