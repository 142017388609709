import React from 'react'
import { useEffect, useState  } from 'react';
import { Link , useLocation} from 'react-router-dom';

export default function Resume() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  
  const [isSidebarVisible, setSidebarVisible] = useState(false);
  const location = useLocation();
  useEffect(() => {
    // Hide sidebar on route change
    setSidebarVisible(false);
  }, [location]);

  const handleToggle = () => {
    setSidebarVisible(!isSidebarVisible);
    document.body.classList.toggle('mobile-nav-active');
  };

  useEffect(() => {
    if (isSidebarVisible) {
      document.body.classList.add('mobile-nav-active');
    } else {
      document.body.classList.remove('mobile-nav-active');
    }
  }, [isSidebarVisible]);
  return (
    <div>
      
  {/* <i class="bi bi-list mobile-nav-toggle d-xl-none"></i> */}

 
  <header id="header">
    <div class="d-flex flex-column">

      <div class="profile">
        <img src="assets/img/propic.jpg" alt="" class="img-fluid rounded-circle"/>
        <h1 class="text-light"><a href="index.html">Migara Thiyunuwan</a></h1>
        <div class="social-links mt-3 text-center">
          <a href="https://web.facebook.com/migara.thiyunuwan.1/" class="facebook"><i class="bx bxl-facebook"></i></a>
          <a href="https://www.youtube.com/@Migara_Thiyunuwan" class="instagram"><i class="bx bxl-youtube"></i></a>
          <a href="https://github.com/MigaraThiyunuwan" class="google-plus"><i class="bx bxl-github"></i></a>
          <a href="https://www.instagram.com/______king_migara______?igsh=MTB5N3N0emEwdzV2aw==" class="twitter"><i class="bx bxl-instagram"></i></a>
          <a href="https://www.linkedin.com/in/migara-thiyunuwan/" class="linkedin"><i class="bx bxl-linkedin"></i></a>
        </div>
      </div>

      <button className="mobile-nav-toggle" onClick={handleToggle}>
      <img  src="assets/img/toggle.png" className="img-fluid" alt=""/>
      </button>
      <nav id="navbar" className={`nav-menu navbar ${isSidebarVisible ? 'show' : ''}`}>
        <ul>
          <li><Link to="/" onClick={handleToggle}><i className="bx bx-home"></i> <span>Home</span></Link></li>
          <li><Link to="/about" onClick={handleToggle}><i className="bx bx-user"></i> <span>About</span></Link></li>
          <li className="nav-link scrollto active"><Link to="/resume" onClick={handleToggle}><i className="bx bx-file-blank"></i> <span>Resume</span></Link></li>
          <li><Link to="/myprojects" onClick={handleToggle}><i className="bx bx-book-content"></i> <span>Projects</span></Link></li>
          <li><Link to="/gallery" onClick={handleToggle}><i className="bx bx-image"></i> <span>Gallery</span></Link></li>
          <li><Link to="/achievements" onClick={handleToggle}><i className="bx bx-star"></i> <span>Achievements</span></Link></li>
          <li><Link to="/contact" onClick={handleToggle}><i className="bx bx-phone"></i> <span>Contact</span></Link></li>
        </ul>
      </nav>
    </div>
  </header>

  <main id="main">

   
    <section class="breadcrumbs">
      <div class="container">

        <div class="d-flex justify-content-between align-items-center">
        <Link to="/" >  <h2 style={{fontWeight:'bold', fontFamily:'inherit'}}>Migara Thiyunuwan</h2> </Link>
          <ol>
            <li><Link to="/">Home</Link></li>
            <li>Resume</li>
          </ol>
        </div>

      </div>
    </section>

    <section id="resume" className="resume">
      <div className="container">

        <div className="section-title">
          <h2>Resume</h2>
          <p style={{ textAlign: 'justify' }}>
          As an undergraduate student passionate about technology, I am enthusiastic about diving into the world of 
          full-stack development. Proficient in PHP, java, laravel, react and spring. I am eager to expand my skills and contribute to innovative projects. 
          Seeking opportunities to gain hands-on experience and grow as a full-stack developer.</p>
        </div>

        <div className="row">
          <div className="col-lg-6" data-aos="fade-up">
            <h3 className="resume-title">Sumary</h3>
            <div className="resume-item pb-0">
              <h4>Migara Thiyunuwan</h4>
              <p style={{ textAlign: 'justify' }}><em>Enthusiastic undergraduate student deeply interested in full-stack development. Eager to embark on a journey in crafting innovative digital solutions. 
                 I am keen to expand my knowledge and contribute to dynamic projects in the realm of full-stack development.</em></p>
              <ul>
                <li>58/1, Waragoda, Aththanagalla.</li>
                <li>(+94)77 14 16 968</li>
                <li>migarathiyunuwan@gmail.com</li>
              </ul>
            </div>

            <h3 className="resume-title">Education</h3>
            <div className="resume-item">
                <h4>uva wellassa university of sri lanka </h4>
              <h5>2021 - present</h5>
              <p><em>Bsc in Computer Science and Technology</em></p>
              <p style={{ textAlign: 'justify' }}>Bachelor of Science (BSc) in Computer Science and Technology degree programme is designed to offer a modern and technology – 
                integrated curriculum ensuring an interdisciplinary learning environment where students are exposed to a multitude of
                 related disciplines broadening their skills needed for a competitive market</p>
                 <div style={{display:'flex', justifyContent:'center'}} class="col-lg-2" data-aos="fade-right">
                <img  src="assets/img/logos/uwu.png" class="img-fluid" alt=""/>
                </div>
            </div>
            <div className="resume-item">
                <h4>AQUINAS COLLAGE OF HIGHER STUDIES</h4>
                <h5>2019 - 2020</h5>
                <p><em>Diploma in English Language and Literature (Not Completed)</em></p>
                <p style={{ textAlign: 'justify' }}>I started to follow Diploma in English Language and Literature in Aquinas Collage of Higher Studies. Unfortunately I could not complete it.</p>
                <div style={{display:'flex', justifyContent:'center', marginTop:10}} class="col-lg-2" data-aos="fade-right">
                <img  src="assets/img/logos/aquinas.png" class="img-fluid" alt=""/>
                </div>
            </div>
            <div className="resume-item">
                <h4>Bandaranayake Central Collage - veyangoda</h4>
              <h5>2010 - 2018</h5>
              <p><em>GCE (A/L) Examination - 2018</em></p>
              <p><em>GCE (O/L) Examination - 2015</em></p>
                <p style={{ textAlign: 'justify' }}>I enrolled at Bandaranayake Central College in Veyangoda after successfully passing the Grade 5 Scholarship Examination. 
                    I pursued my Ordinary Level (O/L) and Advanced Level (A/L) studies at this esteemed institution.</p>
                <div style={{display:'flex', justifyContent:'center'}} class="col-lg-2" data-aos="fade-right">
                <img  src="assets/img/logos/vcc.png" class="img-fluid" alt=""/>
                </div>
            </div>

            <div className="resume-item">
                <h4>sri pangnathissa kanishta vidyalaya</h4>
              <h5>2004 - 2009</h5>
              <p><em>Grade 5 Scholarship Examination - 2009</em></p>
                <p style={{ textAlign: 'justify' }}>I attended Sri Pangnathissa Kanishta Vidyalaya in Atthanagalla for my primary education from Grade 1 to Grade 5.
                     This foundational education provided me with the necessary skills and knowledge to successfully pass the Grade 5 
                     Scholarship Examination.</p>
                
            </div>

        </div>
          <div className="col-lg-6" data-aos="fade-up" data-aos-delay="100">
            <h3 className="resume-title">University Projects</h3>
            <div className="resume-item">
                <h4>Bliss Hub </h4>
                <h5>Jan 2024 - April 2024</h5>
                <p><em>A web application for wedding planning</em></p>
              <ul>
                <li>BlissHub is a wedding planning website developed during the Advanced Programming Techniques course at Uva Wellassa University.</li>
                <li>It features a React frontend and Django backend, enabling seamless event and vendor management.</li>
                <li>Users can create accounts, explore services, order packages, and admins manage operations through a dashboard.</li>
                
              </ul>
            </div>
            <div className="resume-item">
                <h4>web application for a jewellery shop</h4>
                <h5>April 2024 - Present</h5>
                <p><em>3rd Year Main Project </em></p>
                <ul style={{ textAlign: 'justify' }}>
                    <li>Web application for sell and customize jewellery online.</li>
                    <li>Ablility to integrate with gem businesses. </li>
                    <li>Laravel framework.</li>
                </ul>
            </div>
            <div className="resume-item">
                <h4>GARDEN GURU </h4>
                <h5>Aug 2023 - Dec 2023</h5>
                <p><em>A website for Encourage Gardening</em></p>
              <ul style={{ textAlign: 'justify' }}>
                <li>Considering several conditions such as soil, climate, moisture, temperature, sun exposure suggest the best plants.</li>
                <li>Creating a forum for communication where users may discuss gardening-related topics and pose inquiries.</li>
                <li>Allowing users to buy plants and items linked to gardening over the website.</li>
                <li>Allowing employees or advertisers to publish advertisements for gardening-related products or services.</li>
                <li>Creating a forum for communication where users may discuss gardening-related topics and pose inquiries.</li>
              </ul>
            </div>
            <div className="resume-item">
                <h4>Trend Hire </h4>
                <h5>Aug 2023 - Dec 2023</h5>
                <p><em>Online Job Application Portal (JAVA)</em></p>
              <ul>
                <li>Developed an innovative Online Job Application Portal during university's 2nd-year Rapid Application Development course, utilizing JSP and Servlet technology.</li>
                <li>Empowered job seekers to apply for vacancies seamlessly and manage their profiles efficiently, enhancing their job-seeking experience.</li>
                <li>Enabled companies to effortlessly post job vacancies and manage their profiles, expanding their reach to a wider pool of talent.</li>
                <li>Played a key role in designing and implementing the system's architecture, ensuring smooth data flow and integrating robust security features to safeguard user data and privacy. Additionally, developed a comprehensive administration module for overseeing all platform aspects.</li>
              </ul>
            </div>
            <div className="resume-item">
                <h4>Hire Spot </h4>
                <h5>Aug 2023 - Dec 2023</h5>
                <p><em>Online Job Application Portal (PHP)</em></p>
              <ul>
                <li>Developed an innovative Online Job Application Portal during university's 2nd-year Web Application Development course, utilizing PHP technology.</li>
                <li>Empowered job seekers to apply for vacancies seamlessly and manage their profiles efficiently, enhancing their job-seeking experience.</li>
                <li>Enabled companies to effortlessly post job vacancies and manage their profiles, expanding their reach to a wider pool of talent.</li>
     
              </ul>
            </div>
            
          </div>
        </div>

      </div>
    </section>

    <section id="skills" className="skills facts">
      <div className="container">

        <div className="section-title">
          <h2>Skills</h2>
          <p>I possess a diverse set of skills that enhance my ability to deliver high-quality projects. These skills range from programming 
            languages and development methodologies to problem-solving and teamwork capabilities.
             My proficiency in these areas ensures that I can effectively tackle challenges and contribute to the success of any project.</p>
        </div>

        <div className="row skills-content">

          <div className="col-lg-6" data-aos="fade-up">

          <div className="progress">
          <span className="skill">HTML <i className="val">95%</i></span>
          <div className="progress-bar-wrap">
            <div
              className="progress-bar"
              role="progressbar"
              aria-valuenow="100"
              aria-valuemin="0"
              aria-valuemax="100"
              style={{ width: '95%' }}
            >
            </div>
          </div>
        </div>

        <div className="progress">
          <span className="skill">CSS <i className="val">90%</i></span>
          <div className="progress-bar-wrap">
            <div
              className="progress-bar"
              role="progressbar"
              aria-valuenow="90"
              aria-valuemin="0"
              aria-valuemax="100"
              style={{ width: '90%' }}
            >
            </div>
          </div>
        </div>

        <div className="progress">
          <span className="skill">JavaScript <i className="val">65%</i></span>
          <div className="progress-bar-wrap">
            <div
              className="progress-bar"
              role="progressbar"
              aria-valuenow="75"
              aria-valuemin="0"
              aria-valuemax="100"
              style={{ width: '65%' }}
            >
            </div>
          </div>
        </div>
        <div className="progress">
          <span className="skill">React<i className="val">60%</i></span>
          <div className="progress-bar-wrap">
            <div
              className="progress-bar"
              role="progressbar"
              aria-valuenow="75"
              aria-valuemin="0"
              aria-valuemax="100"
              style={{ width: '60%' }}
            >
            </div>
          </div>
        </div>

          </div>

          <div className="col-lg-6" data-aos="fade-up" data-aos-delay="100">

          <div className="progress">
          <span className="skill">PHP <i className="val">80%</i></span>
          <div className="progress-bar-wrap">
            <div
              className="progress-bar"
              role="progressbar"
              aria-valuenow="100"
              aria-valuemin="0"
              aria-valuemax="100"
              style={{ width: '80%' }}
            >
            </div>
          </div>
        </div>

        <div className="progress">
          <span className="skill">JAVA <i className="val">75%</i></span>
          <div className="progress-bar-wrap">
            <div
              className="progress-bar"
              role="progressbar"
              aria-valuenow="90"
              aria-valuemin="0"
              aria-valuemax="100"
              style={{ width: '75%' }}
            >
            </div>
          </div>
        </div>

        <div className="progress">
          <span className="skill">C <i className="val">90%</i></span>
          <div className="progress-bar-wrap">
            <div
              className="progress-bar"
              role="progressbar"
              aria-valuenow="75"
              aria-valuemin="0"
              aria-valuemax="100"
              style={{ width: '90%' }}
            >
            </div>
          </div>
        </div>
        <div className="progress">
          <span className="skill">Laravel <i className="val">65%</i></span>
          <div className="progress-bar-wrap">
            <div
              className="progress-bar"
              role="progressbar"
              aria-valuenow="75"
              aria-valuemin="0"
              aria-valuemax="100"
              style={{ width: '65%' }}
            >
            </div>
          </div>
        </div>

          </div>

        </div>

      </div>
    </section>
  </main>

 
  <footer id="footer">
    <div class="container">
     
      <div class="credits">
        
        Designed & Developed by <a href="{{ asset('/') }}">Migara Thiyunuwan</a>
      </div>
    </div>
  </footer>

  <a href="#" class="back-to-top d-flex align-items-center justify-content-center"><i
      class="bi bi-arrow-up-short"></i></a>


 
    </div>
  )
}
